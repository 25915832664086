<template>
  <div class="container">
    <div class="nav">
      <div class="text">
        <!-- 查询 -->
        <div class="mine">
          <el-form style="margin-bottom: -10px;" :inline="true" class="demo-form-inline" :model="ruleForm">
            <el-form-item label="商品名称" prop="title">
              <el-input placeholder="请输入内容" style="width: 260px;margin-right: 10px;" v-model="ruleForm.name"></el-input>
            </el-form-item>
            <el-form-item label="团长名称" prop="title">
              <el-input placeholder="请输入内容" style="width: 260px;margin-right: 10px;" v-model="ruleForm.realName"></el-input>
            </el-form-item>
            <el-form-item label="供货商名称">
              <el-select style="width: 260px;margin-right: 10px;" v-model="ruleForm.storeId" filterable
                placeholder="请选择供货商" :filter-method="filterData" ref="searchSelect">
                <el-option value="" label="全部"></el-option>
                <el-option v-for="item in storeList" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="创建时间" prop="title">
              <el-date-picker
                    v-model="datatime"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
            </el-form-item>
            <el-form-item>
              <div class="search_main" style="margin-top: 0px">
                <div class="status1" style="display: flex">
                  <div class="demandBtn" @click="search">
                    <img id="images" src="../../../assets/images/search.png" alt="" />
                    查询
                  </div>
                  <div class="resetBtn" @click="reset">
                    <img src="../../../assets/images/reset.png" alt="" />重置
                  </div>
                </div>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <!-- 查询 -->
      </div>
    </div>

    <div style="margin-top: 10px;background-color: white;">
      <!-- 列表 -->
      <div class="body">
        <el-table ref="multipleTable" :data="tableData" style="font-family: PingFangRoutine;" tooltip-effect="dark"
         :header-cell-style="tableHeaderColor">
         <el-table-column prop="brokerData.realName"  label="团长姓名">
          </el-table-column>
          <el-table-column prop="brokerData.mobile"  label="团长手机号">
          </el-table-column>
          <el-table-column prop="storeName" width="180" label="供货商名称">
          </el-table-column>
          <el-table-column prop="id" label="商品ID" width="200">
          </el-table-column>
          <el-table-column label="商品图" >
            <template slot-scope="scope">
              <div id="imagesbtn">
                <img :src="scope.row.mainImageUrl" alt="" />
                <!-- <div class="absPopup" v-if="scope.row.stock == 0">已售罄</div> -->
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="name"  label="商品名称">
            <template slot-scope="scope">
              <div class="overflow">
                {{ scope.row.name }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="costPriceStr" label="成本价">
          </el-table-column>
          <el-table-column prop="priceStr"  label="团购价">
          </el-table-column>
          <el-table-column prop="listPriceStr"  label="划线价">
          </el-table-column>
          <el-table-column prop="stock" label="库存">
          </el-table-column>
          <el-table-column label="操作">
            <template #default="scope" >
              <div class="operating">
                <div class="examine"  @click="examine(scope.row.id)">
                  <i class="iconfont icon-chakan2" style="font-size: 18px;"></i>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div style="margin-top: 10px;"></div>
    <!-- 列表 -->
    <div class="footer">
      <div id="cardfooter">
        <!-- 分页 -->
        <div class="Pagination">
          <div class="Pagination_amin">
            <el-pagination background @current-change="handleCurrentChange" :current-page="1" :page-size="10"
              layout="total, prev, pager, next, jumper" :total="total">
            </el-pagination>
          </div>
          <div class="Pagination_btn" style="height: 28px;">确定</div>
        </div>
        <!-- 分页 -->
      </div>
    </div>

  </div>
</template>

<script>
import {
  goodsTabStatus,
} from "@/api/goods";
import { brokerGoodsList,storeList } from '@/api/group'
import "../../../font-style/font.css";
export default {
  data() {
    return {
      datatime: [], //日期
      tableData: [],
      ruleForm: {
        name: "", //商品名称
        storeId: "", //店铺id
        currPage: "1", //页码数
        startTime:'',
        endTime:'',
        realName:''
      },
      total: 0,
      storeList: [], //店铺列表
      storeQuery: {
        // 店铺列表请求参数
        currPage: 1,
        pageSize: 20,
        name: "",
      },
    };
  },
  created() {
    this.list();
    this.store();
  },
  methods: {
    tableHeaderColor({
      rowIndex
    }) {
      if (rowIndex === 0) {
        return "background-color: rgba(246, 248, 249, 1);color: #333333;fontSize: '14px';";
      }
    },
    list() {
      brokerGoodsList(this.ruleForm).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.total * 1;
      });
    },

    // 店铺列表
    store() {
      storeList(this.storeQuery).then((res) => {
        this.storeList = res.data.list;
      });
    },

    //查看
    examine(id) {
      this.$router.push({
        path: "/group/goods/getById",
        query: {
          id: id,
        },
      });
    },

    // 搜索框内容
    filterData(val) {
      this.storeQuery.name = val;
      this.store();
    },

    // 查询
    search() {
      this.ruleForm.startTime = this.datatime[0];
      this.ruleForm.endTime = this.datatime[1];
      this.total = 0
      goodsTabStatus().then((res) => {
        this.TabStatus = res.data.map((item) => {
          return item.total;
        });
      });
      this.list();
    },

    // 重置
    reset() {
      this.total = 0
      this.datatime=[]
      this.ruleForm.realName = ''
      this.ruleForm.startTime ='';
      this.ruleForm.endTime = '';
      this.ruleForm.name = "";
      this.ruleForm.storeId = "";
      this.storeQuery.name = "";
      this.ruleForm.currPage = "";
      this.store();
      this.list();
    },

    //分页
    handleCurrentChange(val) {
      this.ruleForm.currPage = val;
      this.list();
    },
  },
};
</script>



<style lang="less" scoped>
@import "../../../assets/css/common.less";
.footer {
  padding: 20px;
  padding-left: 0px;
}

.body {
  height: 580px;
  padding-top: 0px;
}

.head {
  padding-top: 18px;
  padding-bottom: 20px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
}

.nav {
  padding-top: 13px;
  padding-bottom: 8px;
}

/deep/.OperButton {
  margin-left: 20px;
  display: inline-block;
  margin-top: 2px;
}

/deep/.el-form--inline .el-form-item__label {
  float: none;
  display: inline-block;
  font-family: PingFang;
  color: #333333;
}

.cancelBox {
  width: 126px;
  height: 42px;
  border-radius: 10px;
}

.sureBox {
  width: 126px;
  height: 42px;
  border-radius: 10px;
}

#dataOperbtn {
  width: 500px;
}

.statusColor {
  background-color: #409EFF;
  color: white;
  width: 60px;
  height: 30px;
  display: block;
  border-radius: 10px;
  text-align: center;
  line-height: 30px;
}

/ddep/ .btnBox {
  height: 64px;
}

.prompt {
  width: 60px;
  height: 60px;
  margin: 0 auto;

  img {
    width: 100%;
    height: 100%;
  }

  margin-bottom: 5px;
}

#text {
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  margin-top: 10px;
}

#btnend {
  display: flex;
  width: 500px;

  div {
    margin-right: 10px;
  }

  .uploadbtn {
    width: 115px;
    height: 36px;
    background: #fc9714;
    border-radius: 10px;
  }

  .downloadbtn {
    width: 115px;
    height: 36px;
    background: #be8dfb;
    border-radius: 10px;
  }

  .groundingbtn {
    width: 115px;
    height: 36px;
    background: #18bea4;
    border-radius: 10px;

    .icontopimg {
      width: 14px;
      height: 14px;
      margin-right: 6px;
    }
  }

  .undercarriagebtn {
    width: 115px;
    height: 36px;
    background: #ffcc33;
    border-radius: 10px;

    .icontopimg {
      width: 14px;
      height: 14px;
      margin-right: 6px;
    }
  }

  .deletebtn {
    width: 115px;
    height: 36px;
    background: #fb6a57;
    border-radius: 10px;
  }
}

#cardfooter {
  display: flex;
  margin-top: 30px;

  .Pagination {
    margin-left: 30px;
  }
}

.el-tabs__nav-wrap::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0px;
  background-color: #e4e7ed;
  z-index: 1;
}

.el-tabs__item.is-active {
  color: #0066ff;
}

/deep/.el-tabs__item {
  width: 300px;
  padding: 0px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
}

/deep/ .el-tabs__nav-scroll {
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.main_top {
  margin-bottom: 30px;
  display: flex;

  .top1 {
    margin-top: 8px;
    width: 4px;
    height: 18px;
    background-color: #0066ff;
    margin-left: 20px;
  }

  .top2 {
    font-size: 18px;
    color: #333333;
    font-weight: bold;
    margin-top: 5px;
    margin-left: 8px;
  }
}

.addbtn {
  margin-left: 0px;
  font-size: 14px;
  background-color: #18bea4;
  color: #fff;
}

#btn:hover {
  opacity: 0.7;
  cursor: pointer;
}

.top_btn {
  margin-top: 20px;
}

.mine {
  margin-top: 10px;
}

.tableHeaderColor {
  color: #fff;
}

.text {
  font-size: 14px;
}

.item {
  padding: 18px 0;
}

.box-card {
  width: 100%;
}

.overflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#imagesbtn {
  margin: auto;
  width: 50px;
  height: 50px;
  position: relative;

  .absPopup {
    width: 100%;
    height: 20px;
    line-height: 20px;
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    text-align: center;
    font-size: 14px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 10;
  }

  img {
    width: 100%;
    height: 100%;
  }
}

/deep/ .cell {
  text-align: center;
}

.el-input__inner {
  margin-right: 10px;
}

/deep/ .btnBox .el-card__body {
  padding-left: 0px;
}

/deep/ #cardfooter {
  display: flex;
  margin-top: 0px;
}

/deep/ .el-table__fixed-right::before,
.el-table__fixed::before {
  height: 0px;
}

/deep/.el-table__fixed,
.el-table__fixed-right {
  position: absolute;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, .12);
}
</style>
