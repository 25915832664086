const groupApi = {
  //获取团长列表
  groupBrokerList: '/group/broker/list',
  //团长详情
  groupBrokerDetail: '/group/broker/getById',
  // 团长审核
  groupBrokerEdit:'/group/broker/edit',
  // 团购订单列表
  groupOrderList:'/group/order/list',
  // 团购订单详情
  groupOrderDetail:'/group/order/getById',
  // 订单状态
  group0rderStatus:'/group/order/orderStatus',
  // 团购订单获取订单简介
  groupOrderFloatById :'/group/order/floatById',
  // 团购商品列表
  groupGoodsList:'/group/goods/init/list',
  // 添加团购商品
  groupGoodsAdd:'/group/goods/add',
  // 获取团购商品回填
  groupGoodsGetById:'/group/goods/getById',
  // 编辑团购商品
  groupGoodsEdit:'group/goods/edit',
  // 团购店铺列表
  groupStoreList:'/store/broker/list',
  // 获取团长商品列表
  groupBrokerGoodsList:'/group/goods/list',
  // 售后列表
  groupAfterList:'/group/after/list',
  // 售后详情
  groupAfterDetail:'/group/after/getById',
  // 换取换取总订单id
  groupOredrGetCombineId:'/group/order/getCombineId',
  // 团购提现列表
  groupCashoutList:'/group/cashout/list',
  // 获取提现详情
  groupCashoutDetail:'/group/cashout/getById'
}


export default groupApi
