import request from "@/utils/request";
import groupApi from '@/const/apiUrl/group';
import contentType from "@/const/request/requestHeader";
import requestType from "@/const/request/requestType";

// 获取团长列表
export function BrokerList(params) {
  return request({
      url: groupApi.groupBrokerList,
      method: 'get',
      data: params,
  })
}

// 获取团长详情
export function BrokerDetail(params) {
  return request({
      url: groupApi.groupBrokerDetail,
      method: 'get',
      data: params
  })
}

// 审核团长
export function BrokerEdit(params) {
  return request({
      url: groupApi.groupBrokerEdit,
      method: 'post',
      data: params
  })
}

// 获取团购订单列表
export function orderList(params) {
  return request({
      url: groupApi.groupOrderList,
      method: 'get',
      data: params,
  })
}

// 获取团购订单详情
export function orderDetail(params) {
  return request({
      url: groupApi.groupOrderDetail,
      method: 'get',
      data: params
  })
}

// 获取团购订单详情
export function orderFloat(params) {
  return request({
      url: groupApi.groupOrderFloatById,
      method: 'get',
      data: params
  })
}

//订单状态
export function orderStatus(status) {
  return request({
      url: groupApi.group0rderStatus,
      method: method.GET,
      data: status
  })
}

// 获取列表
export function goodsList(params) {
  return request({
      url: groupApi.groupGoodsList,
      method: 'get',
      data: params,
  })
}

//新增
export function goodsAdd(params) {
  return request({
      url: groupApi.groupGoodsAdd,
      method: 'post',
      data: params,
      requestType: requestType.ADD,

  })
}

// 获取团购商品详情
export function goodsIdWord (params) {
  return request({
      url: groupApi.groupGoodsGetById,
      method: 'get',
      data: params
  })
}

//编辑
export function goodsEdit(params) {
  return request({
      url: groupApi.groupGoodsEdit,
      method: 'post',
      data: params,
      requestType: requestType.EDIT
  })
}

// 团购店铺列表
export function storeList(params) {
  return request({
      url: groupApi.groupStoreList,
      method: 'get',
      data: params,
  })
}

// 获取团长商品列表

export function brokerGoodsList(params) {
  return request({
      url: groupApi.groupBrokerGoodsList,
      method: 'get',
      data: params,
  })
}

// 售后列表
export function afterList(params) {
  return request({
      url: groupApi.groupAfterList,
      method: 'get',
      data: params,
  })
}

// 售后详情
export function afterDetail(params) {
  return request({
      url: groupApi.groupAfterDetail,
      method: 'get',
      data: params
  })
}

// 换取总订单id
export function oredrGetCombineId(params) {
  return request({
      url: groupApi.groupOredrGetCombineId,
      method: 'get',
      data: params
  })
}

// 售后列表
export function cashoutList(params) {
  return request({
      url: groupApi.groupCashoutList,
      method: 'get',
      data: params,
  })
}

// 售后详情
export function cashoutDetail(params) {
  return request({
      url: groupApi.groupCashoutDetail,
      method: 'get',
      data: params
  })
}
